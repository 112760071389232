@media only screen and (max-width: 768px){
    /* width 767 or less */
    .card-container{
        width: '100%';
        height:450px;
        overflow:'hidden';
    }
    .pack-container{
        width: '100%';
        height:350px;
        overflow:'hidden';
    }
    .pack-container-border{
        margin-bottom:10px;
       
    }
    .carousel-control-prev{
        left:20px;
        bottom:25%;
    }
    
    .carousel-control-next{
        right:20px;
        bottom:25%;
    }
}

@media only screen and (min-width: 768px){
    /* width 768 or more */
    .card-container{
        width: '100%';
        height:700px;
        overflow:'hidden';
    }
    .pack-container{
        width: '100%';
        height:200px;
        overflow:'hidden';
    }
    .pack-container-border{
        margin-bottom:10px;
        
    }
    .truck-carousel{
        width:70%;
        margin:auto;
    }
    .carousel-control-prev{
        left:20px;
        bottom:15%;
    }
    
    .carousel-control-next{
        right:20px;
        bottom:15%;
    }
        
}

.location-search-input {
    width: 100%;
    padding: 10px;
    border-radius: 0px !important;
    background-color: #edf0f3;
}
.loader{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width:60px;
  
}
.search{
    position:absolute;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    top:75px;
}
.carousel-indicators{
    top:0px;
    bottom:unset;
}
.carousel-indicators li{
    background-color:#285aa5;
}

.carousel-control-prev{
    left:20px;
}

.carousel-control-next{
    right:20px;
}

button.button-credit-card {
    line-height: 1.25em;
    vertical-align: middle;
    height: 45px;
    min-width:290px !important;
    width: 100% !important;
    border-radius: 10px;
    text-align: center;
    margin-right: 10px;
    font-size: 22px;
    font-weight: 600;
    background-color: #ef3f6d;
    color: #FFF;
    border-radius:40px;
}

.toast-header{
    background-color: #EDEDED !important;
    border-bottom: unset;
    color:black !important;
    padding-left:70px;
}

.toast-body{
    background-color: #EDEDED !important;
    color:black !important;
    
}