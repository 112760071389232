.current-time {
  text-align: center;
  margin-bottom: 20px;
}

.weather-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.weather-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.current-time p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.weather-icon img {
  max-height: 50px;
  margin-right: 5px; /* Adjust spacing between icon and text */
}

.temperature {
  font-size: 18px;
}

.condition {
  font-size: 16px;
}

.time {
  font-size: 14px;
}
