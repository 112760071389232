OperatorShareALinkScreen.css

/* Container and Layout Styles */
.container-fluid {
    padding: 20px;
}

.card {
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
    font-size: 1.2rem;
    font-weight: bold;
}

/* .card-text {
    font-size: 1.5rem;
    font-weight: bold;
    color: #007bff;
} */

.button-primary {
    margin-top: 10px;
}

/* Chart Styles */
.chart-container {
    margin-top: 30px;
}

.chart-container h3 {
    text-align: center;
    margin-bottom: 20px;
}

/* Calendar Styles */
.react-calendar {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.react-calendar__tile {
    border-radius: 4px;
}

.event {
    padding: 5px;
    color: #fff;
    border-radius: 4px;
}

.event-pending {
    background-color: green;
}

.event-rejected {
    background-color: red;
}

.event-default {
    background-color: yellow;
}

/* Search Bar Styles */
.search-bar-container {
    margin-bottom: 20px;
}

.date-picker {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ced4da;
}

.modal-body {
    padding: 20px;
}

.modal-title {
    font-size: 1.5rem;
}
