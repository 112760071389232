.event-cards-container {
      display: grid;
      grid-template-columns: 1fr;
      gap:20px;
}
@media only screen and (min-width: 768px) {
      /* Your CSS styles here */
      .event-cards-container {
            grid-template-columns: 1fr 1fr;
      }
  }