.table th.up {
    background-image: url("../../../assets//img/up_arrow.png");
  }
  .table th.down {
    background-image: url("../../../assets//img/down_arrow.png");
  }
  .table th.default {
    background-image: url("../../../assets//img/default-arrow.png");
  }
  th.up,
  th.default,
  th.down {
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center right;
  }